import { COMMUNITY_USER_BASE_FRAGMENT } from '@/graphql/_Fragments/CommunityUser/Base';

export const COMMUNITY_USER_BOOKMARK_BASE_FRAGMENT = `
  fragment communityUserBookmarkBaseFragment on CommunityUserBookmark {
    uid
    schemaCode
    targetType
    user {
      ...communityUserBaseFragment
    }
    link {
      uid
      target {
        __typename
        uid
      }
    }
  }
  ${COMMUNITY_USER_BASE_FRAGMENT}
`;
